import { render, staticRenderFns } from "./ProgramSpeakerModal.vue?vue&type=template&id=3d98459a"
import script from "./ProgramSpeakerModal.vue?vue&type=script&lang=ts"
export * from "./ProgramSpeakerModal.vue?vue&type=script&lang=ts"
import style0 from "./ProgramSpeakerModal.vue?vue&type=style&index=0&id=3d98459a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProgramSpeakerItem: require('/builds/project/mashuk/frontend/components/program/ProgramSpeakerItem.vue').default})
